import React, { Component } from "react";
import ContactService from '../services/ContactService';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { withTranslation } from 'react-i18next';
const MySwal = withReactContent(Swal);

const initialState = {
  fullName: "",
  company: "",
  email: "",
  phone: "",
  comments: "",
  emailError: '',
  nameError: '',
};

class ContactPage extends Component {
  state = initialState;

  onChangeHandler = (ev) => {
    const { value, name } = ev.target;
    this.setState({ [name]: value }, () => {});
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const isValide = this.validateForm();
    if (isValide) {
      const applied = await ContactService.sendContactForm({...this.state})

      if (applied) {
        this.sendUserMsg()
      }
      this.setState(initialState);
    }
  };

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validateForm = () => {
    const {t} = this.props;
    let nameError = "";
    let emailError = "";

    if(!this.state.fullName) {
      nameError = t('errors.nameRequired')
    }
    if (!this.state.email) {
      emailError = t('errors.emailRequired');
    } else if (!this.validateEmail(this.state.email)) {
      emailError = t('errors.emailInvalid');
    }

    if (emailError || nameError) {
      this.setState({ emailError , nameError});
      return false;
    }
    return true;
  };

  sendUserMsg = () => {
    const {t} = this.props;
    MySwal.fire({
      position: 'top-end',
      icon: 'success',
      title: t('message.thanks'),
      text: t('message.weWillContactYou'),
      showConfirmButton: true,
      timer: 3500
      });
  };

  render() {
    const t = this.props.t;
    
    return (
      <div className="contact-page-container">
        <h2>{t("contact.contact")}</h2>
        <h3>{t("contact.instructions")}</h3>
        <form onSubmit={this.handleSubmit} className="form-box" noValidate>
          <div className="form-row flex column">
            <label htmlFor="name">{t("form.name")}<i>*</i></label>
            <input
              type="text"
              value={this.state.fullName}
              onChange={this.onChangeHandler}
              name="fullName"
            />
            <div className="validation-error">
              {this.state.nameError}
            </div>
          </div>
          <div className="form-row flex column">
            <label htmlFor="name">{t("form.company")}</label>
            <input
              type="text"
              value={this.state.company}
              onChange={this.onChangeHandler}
              name="company"
            />
          </div>
          <div className="form-row flex column">
            <label htmlFor="email">{t("form.email")}<i>*</i></label>
            <input type="email"
             value={this.state.email}
             onChange={this.onChangeHandler}
            name="email" />
            <div className="validation-error">
              {this.state.emailError}
            </div>
          </div>
          <div className="form-row flex column">
            <label htmlFor="phone">{t("form.phone")}</label>
            <input type="number"
            value={this.state.phone}
            onChange={this.onChangeHandler}
            name="phone" />
          </div>
          <div className="form-row flex column">
            <label htmlFor="comments">{t("form.comments")}</label>
            <textarea type="text" 
            value={this.state.comments}
            onChange={this.onChangeHandler}
            name="comments" />
          </div>
          <div className="btns flex column">
            <button type="submit" className="submit-btn">
            {t("form.send")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default  withTranslation('translations')(ContactPage);

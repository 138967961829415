import httpService from './httpService'

async function sendContactForm(contactInfo) {
  const res = await httpService.post('mail/contact', contactInfo)
  console.log('Mail sent');
  return res
  
}

export default {
  sendContactForm
}
import React from 'react';
import CompanyStory from './CompanyStory';
import CompanyValue from './CompanyValue';
const CompanyDetails = () => {
  return (
    <div className="company-details-container flex space-between main-layout-container">
      <CompanyStory />
      <CompanyValue />
    </div>
  );
};

export default CompanyDetails;

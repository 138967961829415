import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CompanyStatement = () => {
  const { t } = useTranslation('translations');

  return (
    <div className="company-statement-container flex column align-center justify-center main-layout-container">
      <div className="company-statement text-center">{t('statement')}</div>
      <Link to="/contact" className="contact-btn btn">
        {t('header.contact')}
      </Link>
    </div>
  );
};

export default CompanyStatement;

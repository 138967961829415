import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  const { t } = useTranslation('translations');
    return (
        <div className="page-not-found-container">
            <h1>404</h1>
            <h2>{t('404.not-found')}</h2>
            <p>{t('404.not-exist')}</p>
            <Link to="/">{t('404.back-home')}</Link>
        </div>
    );
};

export default PageNotFound;

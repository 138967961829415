import React from "react";
import TeamPreview from "./TeamPreview";
import { useTranslation } from "react-i18next";
import fullPenta from '../../assets/imgs/Polygon 1.png'


const TeamList = () => {
  const { t, i18n } = useTranslation("translations");
  const isRtl = i18n.dir() === "rtl";
  const teamMembers = [
    {
      icon: "doron",
      name: t("team.doron.name"),
      title: t("team.doron.title"),
      phone: "972-52-3400854",
    },
    {
      icon: "nizar",
      name: t("team.nizar.name"),
      title: t("team.nizar.title"),
      phone: "972-52-3630441",
    },
    {
      icon: "esti",
      name: t("team.esti.name"),
      title: t("team.esti.title"),
      phone: "972-97440501",
    },
  ];

  return (
    <div className="team-list-container main-layout-container">
      <img className={`penta ${isRtl? "he-pos" : "en-pos"}`} src={fullPenta} alt="polygon"/>
      <div className="team-list-header">
        <h2>{t("team.meet")}</h2>
        <h1>{t("team.team")}</h1>
      </div>
      <div className="team-list flex justify-center space-around wrap">
        {teamMembers.map((m, idx) => {
          return (
            
            <TeamPreview
              icon={m.icon}
              name={m.name}
              title={m.title}
              phone={m.phone}
              key={idx}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TeamList;

import React, { useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { permissions, currentObservedUser } from './services/AuthService.js';
import { useTranslation } from 'react-i18next';

import HomePage from './pages/HomePage.jsx';
import ProductPage from './pages/ProductPage.jsx';
import AppHeader from './cmps/AppHeader.jsx';
import AppFooter from './cmps/AppFooter.jsx';
import LoginPage from './pages/LoginPage.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';
import SignupPage from './pages/SignupPage.jsx';
import ContactPage from './pages/ContactPage.jsx';
import PrivateRoute from './cmps/PrivateRoute.jsx';
import AdminPage from './pages/AdminPage.jsx';
import ResetPassword from './pages/ResetPassword.jsx';
import PageNotFound from './pages/PageNotFound.jsx';

const history = createBrowserHistory();

function App() {
    const [currentUser, setCurrentUser] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavBar = () => {
        setIsOpen(!isOpen);
    };

    const { i18n } = useTranslation();
    const isRtl = i18n.dir() === 'rtl';
    // const direction = {
    //   direction: isRtl ? "rtl" : "ltr",
    //   transition: '5s'
    // }

    useEffect(() => {
        currentObservedUser.subscribe((user) => {
            setCurrentUser(user);
        });
    }, [currentUser]);

    return (
        <Router history={history}>
            <div className={`main-app-container flex column ${isRtl ? 'right' : 'left'} ${isOpen ? 'open-nav' : ''}`}>
                <div className="screen" onClick={toggleNavBar}></div>
                <AppHeader user={currentUser} openNav={toggleNavBar} />
                <main className="main-view-container">
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/login" exact component={LoginPage} />
                        <Route path="/forgot" exact component={ForgotPassword} />
                        <Route path="/signup" exact component={SignupPage} />
                        <PrivateRoute
                            path="/product"
                            component={ProductPage}
                            permissions={[permissions.Admin, permissions.User, permissions.Moderator]}
                        />
                        <PrivateRoute path="/admin" permissions={[permissions.Admin]} component={AdminPage} />
                        <Route path="/contact" exact component={ContactPage} />
                        <Route path="/reset/:id" exact component={ResetPassword} />
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </main>
                <AppFooter />
            </div>
        </Router>
    );
}

export default App;

import React, { Component } from "react";
import ProductService from "../services/ProductService.js";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import ProductList from "../cmps/product/ProductList";
import ProductFilter from "../cmps/product/ProductFilter";
import ProductLimit from "../cmps/product/ProductLimit.jsx";
import ProductExport from "../cmps/product/ProductExport.jsx";
import ProductPaging from "../cmps/product/ProductPaging.jsx";
import { Loading } from "../cmps/Loading";

class ProductPage extends Component {
  state = {
    redirect: false,
    products: [],
    filterBy: {
      name: "",
      code: '',
      manufacturer: '',
      limit: 50,
      page: 0,
    },
    rowCount: null,
    fullData: null,
    isFetching: false
  };


  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = async () => {
    const results = await ProductService.loadProducts(this.state.filterBy);

    // if no user is logged in redirect to login page
    if (results === undefined) {
      this.setState({ redirect: true });
    }

    if (results) {
      const { products, rowCount } = results;
      this.setState({ ...this.state, products, rowCount, isFetching: false });
    }
  };

  setFilterBy = (filterBy) => {
      this.setState((prevState) => {

        if (filterBy.term || filterBy.limit) {
          filterBy.page = 0;
        }
        return {
          isFetching: true,
          filterBy: {
            ...prevState.filterBy,
            ...filterBy,
          },
        };
      }, this.loadProducts);
  };

  getDataToExport = async () => {
    const filterBy = this.state.filterBy;
    filterBy.limit = "all";
    const { products } = await ProductService.loadProducts(filterBy);
    return products;
  };

  render() {
    const { products, filterBy, rowCount, redirect, isFetching } = this.state;
    const { t } = this.props;

    if (redirect) return <Redirect to="/login" />;

    const listDisplay = !products ||  isFetching ? (
      <Loading />
    ) : !products.length ? (
      <h1 className="no-content">{t("display.nothing")}</h1>
    ) : (
      <ProductList products={products} />
    );
    const pagingDisplay =
      !products || !products.length ? (
        <div></div>
      ) : (
        <ProductPaging
          setPage={this.setFilterBy}
          rowCount={rowCount}
          limit={filterBy.limit}
          page={filterBy.page}
        />
      );

    return (
      <div className="product-page-container main-layout-container">
        <div className="product-top-bar">
          <ProductLimit setFilterBy={this.setFilterBy} />
          <ProductFilter
            filterBy={filterBy}
            setFilterBy={this.setFilterBy}
          />
          <ProductExport
            data={products}
            rowCount={rowCount}
            getDataToExport={this.getDataToExport}
          />
        </div>
        {listDisplay}
        {pagingDisplay}
      </div>
    );
  }
}

export default withTranslation("translations")(ProductPage);

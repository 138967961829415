import React from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import AuthService, { permissions } from "../services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const bars = <FontAwesomeIcon icon={faBars} />;

const AppHeader = (props) => {

  const { t, i18n } = useTranslation("translations");
  const dir = i18n.dir();
  const isRtl = dir === 'rtl'
  
  let history = useHistory();

  const logout = async () => {
    await AuthService.logout();
    history.replace("/");
  };

  const changeLang = (ev) => {
    const {value} = ev.target;
    i18n.changeLanguage(value);
  };

  const toggleNav = () => {
    props.openNav()
  }

  return (
    <div className={`app-header-container flex align-center main-layout-container ${isRtl? 'he-pos': 'en-pos'}`}>
      <div className={`nav-bar-container ${isRtl? 'he-pos': 'en-pos'}` } onClick={toggleNav}>
        <NavLink to="/" exact activeClassName="activeLink">
          {t("header.home")}
        </NavLink>
        <NavLink to="/product" exact activeClassName="activeLink">
          {t("header.products")}
        </NavLink>
        <NavLink to="/contact" exact activeClassName="activeLink">
          {t("header.contact")}
        </NavLink>
        {!props.user && (
          <NavLink to="/login" exact activeClassName="activeLink">
            {t("header.login")}
          </NavLink>
        )}

        {props.user && props.user.permission === permissions.Admin && (
          <NavLink to="/admin/" exact activeClassName="activeLink">
            {t("header.admin")}
          </NavLink>
        )}
        {props.user && (
          <button className="logout-btn" onClick={logout}>
            {t("header.logout")}
          </button>
        )}
      </div>

      <Link to="/" className="logo" />
      
      <div className="lang-switch">
        
        <select id="lang" onChange={changeLang}  value={dir === 'rtl'? 'he' : 'en'}>
          <option value="en">EN</option>
          <option value="he">עב</option>
        </select>

      </div>
          <div className="hamburger" onClick={toggleNav}>{bars}</div>
    </div>
  );
};

export default AppHeader;

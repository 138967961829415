import React from 'react';
import CompanyHero from '../cmps/home/CompanyHero';
import CompanyDetails from '../cmps/home/CompanyDetails';
import CompanyStatement from '../cmps/home/CompanyStatement';
import TeamList from '../cmps/home/TeamList';

const HomePage = () => {
  return (
    <div className="home-page-container flex column">
      <CompanyHero />
      <CompanyStatement />
      <div className="light-background-screen">
        <CompanyDetails />
      </div>
      <TeamList />
    </div>
  );
};

export default HomePage;

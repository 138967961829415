import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ProductFilter = (props) => {
  const { t } = useTranslation("translations");
  const { setFilterBy, filterBy } = props;

  const [filter, setFilter] = useState(filterBy);
  const [isFiltering, setIsfiltering] = useState(false);

  useEffect(() => {
    setFilterBy(filter);
    setIsfiltering(false);
  }, [filter, isFiltering, setFilterBy]);

  const onSubmit = (ev) => {
    ev.preventDefault();
    setIsfiltering(true);
  };

  const handleFilterBy = (ev) => {
    ev.preventDefault();
    setFilter({ ...filter, [ev.target.name]: ev.target.value });
  };

  const cleanFilter = () => {
    setFilter({ name: "", code: "", manufacturer: "" });
    setIsfiltering(true);
  };

  return (
    <div className="product-filter-container">
      <form onSubmit={onSubmit} className="flex align-center">
        <input
          className="term-input main-input"
          type="text"
          value={filter.name}
          name="name"
          onChange={handleFilterBy}
          placeholder={t("filter.name-placeholder")}
        />
        <input
          className="code-input sec-input"
          type="number"
          value={filter.code}
          name="code"
          onChange={handleFilterBy}
          placeholder={t("filter.code-placeholder")}
        />
        <input
          className="manu-input sec-input"
          type="text"
          value={filter.manufacturer}
          name="manufacturer"
          onChange={handleFilterBy}
          placeholder={t("filter.manufacturer-placeholder")}
        />
        <button type="submit" className="filter-btn btn">
          {t("filter.search")}
        </button>
        <button
          onClick={cleanFilter}
          type="button"
          className="clear-btn sec-btn"
        >
          {t("filter.clear")}
        </button>
      </form>
    </div>
  );
};

export default ProductFilter;

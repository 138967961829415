import React from "react";
const icons = require.context('../../assets/icons/', true)



const ValueCmp = (props) => {
  
  const { icon, header, content, num } = props;
  let iconPng = icons( './'+icon +'.png')
  return (
    <div  className={`value-cmp-container flex value${num}`} >
    {/* <div style={{marginTop: `${25 * (num -1)}px`, marginInlineStart: `${25 * (num -1)}px`}}  className={`value-cmp-container flex column value${num}`} > */}
      <div className="icon flex justify-center"><img src={iconPng} alt={header} /></div>
      <div className="header">{header}</div>
      <div className="content align-center flex grow-1">{content}</div>
    </div>
  );
};

export default ValueCmp;

import React from 'react';
import ValueCmp from './ValueCmp';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';

const CompanyValue = () => {
    const { t } = useTranslation('translations');
    const valuesObjs = [
        {
            icon: 'team',
            header: t('values.team-header'),
            content: t('values.team-content'),
        },
        {
            icon: 'list',
            header: t('values.list-header'),
            content: t('values.list-content'),
        },
        {
            icon: 'computer',
            header: t('values.erp-header'),
            content: t('values.erp-content'),
        },
        {
            icon: 'customize',
            header: t('values.custom-header'),
            content: t('values.custom-content'),
        },
    ];
    return (
        <div className="company-value-container flex wrap space-between">
            {valuesObjs.map((val, idx) => {
                return (
                    <ScrollAnimation animateIn="fadeInLeft" duration={2} delay={70 * idx} key={idx} animateOnce={true}>
                        <ValueCmp icon={val.icon} header={val.header} content={val.content} num={idx + 1} key={idx} />
                    </ScrollAnimation>
                );
            })}
        </div>
    );
};

export default CompanyValue;

import React from 'react'
import ReactLoading from 'react-loading';

export const Loading = () => {
  return (
    <div className="loading-container flex justify-center align-center">
      <ReactLoading color={'#128C9E'} width={128} height={128} delay={100} type={'spinningBubbles'} />
    </div>
  )
}

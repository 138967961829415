import httpService from "./httpService";
const convert = require("xml-js");

async function loadProducts(filterBy) {
  const queryParams = new URLSearchParams();
  if (filterBy) {
    queryParams.append("name", filterBy.name);
    queryParams.append("code", filterBy.code);
    queryParams.append("manufacturer", filterBy.manufacturer);
    queryParams.append("limit", filterBy.limit);
    queryParams.append("page", filterBy.page);
  }
  // get products
  const results = await httpService.get(`product?${queryParams}`);
  return results;
}

async function updateProducts(file) {
  // declare a new FileReder
  const reader = new FileReader();
  // read file as text
  reader.readAsText(file);

  //after finish reading
  return new Promise((resolve, reject) => {
    reader.onload = async (ev) => {
      const readerData = ev.target.result;
      // convert the xml file to a javascript object
      var xmlObj = convert.xml2js(readerData, {compact: true, textKey: '#text', 
      textFn: (val) => { 
        val = val.replace(/'/gi, '\'\'')
        val = val.replace(/\\$/, '')
        return val 
      }, ignoreDeclaration: true , spaces: 4});
      
      const updatedProducts = await httpService.post('product', {xmlObj});
      resolve( updatedProducts)

    };
    reader.onerror = (err) => {
      console.log("ERROR");
      reject(err);
    };
  })
  }

export default {
  loadProducts,
  updateProducts
};

import React, { useEffect, useState } from 'react';
import passwordService from '../services/PasswordService.js';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const initialState = {
    pass: '',
    confirmPass: '',
    passError: '',
    confirmPassError: '',
};

const ResetPassword = (props) => {
    const { t, i18n } = useTranslation('translations');
    const isRtl = i18n.dir() === 'rtl';
    const [state, setstate] = useState(initialState);
    const [hiddenPass, setHiddenPass] = useState({ first: true, second: true });

    useEffect(() => {
        getTokenId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    const getTokenId = async () => {
        const passToken = props.match.params.id;
        console.log('tokenId', passToken);
        const tokenObj = await passwordService.isTokenValide(passToken);
        if (!tokenObj) {
            return sendUserMsg('error');
        }
        setstate({ ...state, userId: tokenObj.user_id });
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setstate({ ...state, passError: '', confirmPassError: '' });
        const isValid = validateForm();
        if (!isValid) return;
        await passwordService.updatePassword({ password: state.pass, userId: state.userId });
        sendUserMsg('success');
    };

    const onChangeHandler = (ev) => {
        const { value, name } = ev.target;
        setstate({ ...state, [name]: value });
    };

    const validateForm = () => {
        let passError = '';
        let confirmPassError = '';

        if (!state.pass) {
            passError = t('errors.passRequired');
        } else if (state.pass !== state.confirmPass) {
            confirmPassError = t('errors.passConfirm');
        }

        if (passError || confirmPassError) {
            setstate({ ...state, passError, confirmPassError });
            return false;
        }
        return true;
    };

    const sendUserMsg = (message) => {
        const options = {
            position: 'center',
            showConfirmButton: true,
            timer: 5000,
        };

        if (message === 'success') {
            options.icon = 'success';
            options.title = t('message.successReset');
        } else if (message === 'error') {
            options.icon = 'error';
            options.title = t('message.somthingHappened');
            options.text = t('message.tryAgain');
        }
        MySwal.fire(options).then(() => {
            props.history.push('/login');
        });
    };

    const togglePasswordDispaly = (passsInput) => {
        hiddenPass[passsInput] = !hiddenPass[passsInput];
        setHiddenPass({ ...hiddenPass });
    };

    return (
        <section className="reset-password-container">
            <form className="form-box" onSubmit={handleSubmit} noValidate>
                <h3>{t('form.enter-pass')}</h3>
                {/* <div className="form-row password-input flex column"> */}
                <div className="form-row flex column">
                    <label>
                        {t('form.pass')}
                        <i>*</i>
                    </label>
                    <div className="password-input">
                        <input
                            type={hiddenPass.first ? 'password' : 'text'}
                            value={state.pass}
                            onChange={onChangeHandler}
                            name="pass"
                            autoComplete="current-password"
                        />

                        <div
                            className={`show-password ${isRtl ? 'he-pos' : 'en-pos'}`}
                            title={hiddenPass.first ? 'show password' : 'hide password'}
                            onClick={() => togglePasswordDispaly('first')}
                        >
                            {hiddenPass.first ? eye : eyeSlash}
                        </div>
                    </div>

                    <div className="validation-error">{state.passError}</div>
                </div>
                {/* <div className="form-row password-input flex column"> */}
                <div className="form-row flex column">
                    <label>
                        {t('form.confirm-pass')}
                        <i>*</i>
                    </label>
                    <div className="password-input">
                        <input
                            type={hiddenPass.second ? 'password' : 'text'}
                            value={state.confirmPass}
                            onChange={onChangeHandler}
                            name="confirmPass"
                            autoComplete="current-password"
                        />

                        <div
                            className={`show-password ${isRtl ? 'he-pos' : 'en-pos'}`}
                            title={hiddenPass.second ? 'show password' : 'hide password'}
                            onClick={() => togglePasswordDispaly('second')}
                        >
                            {hiddenPass.second ? eye : eyeSlash}
                        </div>
                    </div>

                    <div className="validation-error">{state.confirmPassError}</div>
                </div>

                <div className="btns flex column">
                    <button type="submit" className="submit-btn">
                        {t('form.send')}
                    </button>
                </div>
            </form>
        </section>
    );
};

export default ResetPassword;

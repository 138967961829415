import React from "react";
import XLSX from "xlsx";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProductExport = (props) => {
  const { t } = useTranslation("translations");
  const getDataToExport = async () => {
    if(props.rowCount === 0) return
    const dataToExport = await props.getDataToExport();
    exportToXlsx(dataToExport);
  };

    const exportToXlsx = (data) => {
    // remove _id property from obj
    data = data.map((row) => {
      delete row._id;
      return row;
    });

    const wb = XLSX.utils.book_new(); // create a new empty workbook
    const ws = XLSX.utils.json_to_sheet(data); // create a sheet from json
    XLSX.utils.book_append_sheet(wb, ws, "price26"); // appends sheet to workbook
    XLSX.writeFile(
      wb,
      `Product_Price ${moment(Date.now()).format("DD-MM-YYYY")}.xlsx`
    ); // download file
  };

  return (
    <div className="product-export-container flex column">
      <button onClick={() => getDataToExport()} className="sec-btn export-btn">
        {t("export.export")} {props.rowCount} {t("export.entries")}
      </button>
    </div>
  );
};
export default ProductExport;

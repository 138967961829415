import 'core-js/stable'
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "../src/styles/style.scss";
import "animate.css/animate.compat.css";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Loading } from './cmps/Loading'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-192651112-1', { debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);

window.addEventListener('beforeinstallprompt', (e) => {
  console.log('beforeinstallprompt event has fired')
  // e.prompt('This app is availabe for download as a PWA')
});


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={(<Loading />)}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

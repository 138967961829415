import React from "react";

const CompanyHero = () => {
  return (
    <div className="company-hero-container">
      <div className="hero-img-container flex justify-start"></div>
    </div>
  );
};

export default CompanyHero;

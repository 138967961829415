import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const imgs = require.context("../../assets/imgs/team/square", true);

const TeamPreview = (props) => {
  const {icon, name, title, phone} = props;


  let iconPng = imgs("./" + icon + "_square.png");
  return (
    <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}> 
    <div className="team-preview-container flex space-around">
      <div className="team-img">
        <img src={iconPng} alt="" />
      </div>
      <div className="team-info flex column space-around align-center">
        <div className="team-name">{name}</div>
        <div className="team-title">{title}</div>
        <div className="team-phone">{phone}</div>
      </div>
    </div>
    </ScrollAnimation>
  );
};

export default TeamPreview;

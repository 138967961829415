import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';

const CompanyStory = () => {
    const { t } = useTranslation('translations');
    return (
        <ScrollAnimation className="company-story-animated" animateIn="fadeInUp" duration={2} animateOnce={true}>
            <div className="company-story-container">
                <div className="story-header">
                    <h2>{t('story.our')}</h2>
                    <h1>{t('story.story')}</h1>
                </div>
                <div className="story-content">
                    <p>{t('story.text')}</p>
                    <p>{t('story.text2')}</p>
                </div>
            </div>
        </ScrollAnimation>
    );
};

export default CompanyStory;

import httpService from './httpService';
// import storageService from '../services/StorageService';

async function isTokenValide(passToken) {
  return await httpService.post('password/token', { passToken });
}

// PASSWORD RESET REQUEST
async function resetPassword(email) {
  const res = await httpService.post('password/passwordReset', { email });
  return res;
}

async function updatePassword(payload) {
  const res = await httpService.post('password/updatePassword',  payload );
  return res;
}

export default {
  isTokenValide,
  resetPassword,
  updatePassword
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserService from "../services/UserService";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { withTranslation } from 'react-i18next';
const MySwal = withReactContent(Swal);

const initialState = {
  fullName: "",
  company: "",
  email: "",
  phone: "",
  streetAddress: "",
  city: "",
  nameError: "",
  emailError: "",
  phoneError: "",
};

// USER SIGNUP PAGE
class SignupPage extends Component {
  state = initialState;

  onChangeHandler = (ev) => {
    const { value, name } = ev.target;
    this.setState({ [name]: value }, () => {});
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();
    
    const isValide = this.validateForm();
    if (isValide) {
      const applied = await UserService.apply({...this.state})
      
      if (applied) {
        this.sendUserMsg()
      }

      this.setState(initialState);
    }
  };

  sendUserMsg = () => {
    const {t} = this.props
    MySwal.fire({
      position: 'top-end',
      icon: 'success',
      title: t("message.thanks"),
      text: t("message.weWillContactYou"),
      showConfirmButton: true,
      timer: 3500
        
      });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validateForm = () => {
    const {t} = this.props;
    let nameError = "";
    let emailError = "";
    let phoneError = "";

    if(!this.state.fullName) {
      nameError = t('errors.nameRequired')
    }
    if (!this.state.email) {
      emailError = t('errors.emailRequired');
    } else if (!this.validateEmail(this.state.email)) {
      emailError = t('errors.emailInvalid');
    }
    if(!this.state.phone) {
      phoneError = t('errors.phoneRequired')
    }
    
    
    if (nameError || emailError || phoneError) {
      this.setState({ nameError, emailError, phoneError });
      // console.log('No full name');
      return false;
    }
    return true;
  };

  render() {
    const {t} = this.props
    return (
      <div className="signup-page-container">
        <h2>{t("form.signup")}</h2>
        <form onSubmit={this.handleSubmit} className="form-box" noValidate>
          <div className="form-row flex column">
            <label htmlFor="name">{t("form.name")}<i>*</i></label>
            <input
              type="text"
              value={this.state.fullName}
              onChange={this.onChangeHandler}
              name="fullName"
            />
            <div className="validation-error">{this.state.nameError}</div>
          </div>

          <div className="form-row flex column">
            <label htmlFor="company">{t("form.company")}</label>
            <input
              type="text"
              value={this.state.company}
              onChange={this.onChangeHandler}
              name="company"
            />
          </div>

          <div className="form-row flex column">
            <label htmlFor="email">{t("form.email")}<i>*</i></label>
            <input
              type="email"
              value={this.state.email}
              onChange={this.onChangeHandler}
              name="email"
            />
            <div className="validation-error">{this.state.emailError}</div>
          </div>
          <div className="form-row flex column">
            <label htmlFor="phone">{t("form.phone")}<i>*</i></label>
            <input
              type="number"
              value={this.state.phone}
              onChange={this.onChangeHandler}
              name="phone"
            />
            <div className="validation-error">{this.state.phoneError}</div>
          </div>
          <div className="line" />

          <h4>{t("form.address")}</h4>

          <div className="address flex space-between">
            <div className="form-row flex column">
              <label htmlFor="streetAddress">{t("form.st&num")}</label>
              <input
                type="text"
                value={this.state.streetAddress}
                onChange={this.onChangeHandler}
                name="streetAddress"
              />
            </div>

            <div className="form-row flex column">
              <label htmlFor="city">{t("form.city")}</label>
              <input
                type="text"
                value={this.state.city}
                onChange={this.onChangeHandler}
                name="city"
              />
            </div>
          </div>

          <div className="btns flex column">
            <button type="submit" className="submit-btn">
            {t("form.signup")}
            </button>
            <Link to="/login" className="sec-btn">
            {t("login.alreadySignup")}
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('translations')(SignupPage);

import React from 'react';
import { useTranslation } from 'react-i18next';
import httpService, { BASE_URL } from '../services/httpService';

const AppFooter = () => {
  const { t, i18n } = useTranslation('translations');

  const downloadAccessabilityStatement = async () => {
    const langKey = i18n.language;

    try {
      const response = await fetch(`${BASE_URL}file/accessability/${langKey}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `pharma-soft_accessibility-statement_${langKey}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div className="app-footer-container">
      <main className="main-layout-container flex column gap-05">
        <div className="rights">
          <p>{t('footer.rights')} &nbsp; &copy;</p>
          <p>{t('footer.address')}</p>
        </div>

        <div className="flex space-between">
          <button className="simple-btn" onClick={downloadAccessabilityStatement}>
            | {t('footer.accessability-statement')} |
          </button>
        </div>
        <div className="attrbutions flex align-baseline gap-1">
          <p>Created by Rotem Carmon</p>
          <div className="credits ">
            <div>
              {' '}
              Icons made by{' '}
              <a href="https://www.flaticon.com/authors/monkik" title="monkik">
                monkik
              </a>{' '}
              &{' '}
              <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
                Freepik
              </a>{' '}
              from{' '}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AppFooter;
